import MdCaption from "./ContentDict";
import { useState } from 'react';

type h1State = {
    id:number;
    statu:boolean;
};

function SideBarList(){
    const captionDict = MdCaption();
    const [clicked_h1, seth2_list] = useState<h1State[]>([]);

    const handleClick = (index:number) => {
        seth2_list((prevState:any) => ({
            ...prevState,
            [index]:!prevState[index]
        }));
    };

    return(
        <div className="Side-nav shadow">
            <ul>
                {Object.keys(captionDict).map((title:string, index:number) =>{
                    return (
                        <div key={index}>
                            <div onClick={()=>handleClick(index)} className={clicked_h1[index]? 'h2-open':'h2-nonopen'}/>
                            <a onClick={()=>handleClick(index)} href={"#"+title}>{title}</a>
                            <ul className={clicked_h1[index]? 'h1-clicked':'h1-nonclick'}>
                                {captionDict[title].map((h2:string, index) =>
                                    <li key={index}><a href={"#"+h2}>{h2}</a></li>)
                                }
                            </ul>
                        </div>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default SideBarList