import { logout } from '../firebase';

const Header = () => {
    return (
      <header className="App-header">        
        <button style={{marginLeft:"auto"}} type='button' onClick={logout}>ログアウト</button>
        <h1 style={{marginTop:"0px"}}  >Hello, Python!</h1>
      </header>
  );
};

export default Header;