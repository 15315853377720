import Markdown from 'markdown-to-jsx';
import RenderContent from './readMd';
import contentList from "../docs/contents.json"
import { useAuth } from '../contexts/AuthContext';


const Contents = () => {
    const {user} = useAuth();
    const title = contentList.title;

    return(
        <section>
            {title.map((h1:string, index:number) =>
                <Markdown options={{ slugify: str => str }} key={index}>
                    {user?.substring(0,7)==='teacher' ? RenderContent(h1):RenderContent(h1).replace(/<details>[\s\S]*?<\/details>/g, "")}
                </Markdown>
            )}
        </section>
    )
}


export default Contents;