import { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from '../firebase';

type AuthContextProps = {
    user:  string| null
}

const AuthContext = createContext<AuthContextProps>({
    user: null,
})
export function useAuthContext() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<string | any>(null)
  const [loading, setLoading] = useState(true)



  useEffect(() => {
      const unsubscribed = onAuthStateChanged(firebaseAuth, (user) => {
          if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uemail = user.email;
            setUser((_: any) => uemail);
          // ...
          } else {
            // User is signed out
            // ...
            unsubscribed();
          }
          setLoading(false);
      });
  });
  return (
    <AuthContext.Provider value={{ user }}>
      {!loading && children}
    </AuthContext.Provider>
  )
//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export const useAuth = () => {
  return useContext(AuthContext)
}