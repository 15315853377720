import { useState, useEffect } from "react";

const RenderContent = (file_name:string) =>{
    
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`../docs/markdwon/${file_name}.md`)
            .then(res => {
                fetch(res.default)
                .then(res => res.text())
                .then(res => setPost(res.replace(/&emsp;/g, "　")))
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    },[file_name]);

    return post;
}

export default RenderContent;