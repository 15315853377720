import './App.css';
import Header from './components/Header';
import Login from './components/Login';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
    <div className="App">
      <Header></Header>
      <Login></Login>
    </div>
    </AuthProvider>
  );
}

export default App;
