import MdJson from '../docs/contents.json'
import RenderContent from './readMd';

/*
* jsonファイルからh1をキー、h2を文字列配列の値とする辞書を作成
*/

const MdCaption = () => {

    let h_dict :{[key:string]:string[]} = {};
    
    const h1_list = MdJson.title;
    const h2_list = h1_list.map((h1:string) => (RenderContent(h1).match(/\n## [\s\S]*?\n/g) ?? []).map(elm => elm.replace("##", "").trim()));

    for(let i = 0; i<h1_list.length;i++){
        h_dict[h1_list[i]] = h2_list[i]
    }

    return h_dict;
}

export default MdCaption;