import { getApps, initializeApp} from 'firebase/app';
import { getAuth, connectAuthEmulator,signInWithEmailAndPassword,signOut } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databeseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSEGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


if (!getApps().length) {
  initializeApp(config);
}

const auth = getAuth();

const db = getFirestore();

if (process.env.NODE_ENV !== "production") {
  connectAuthEmulator(auth, "http://localhost:" + process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT as string);
  connectFirestoreEmulator(db, 'localhost', Number(process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT));
}



export const firebaseAuth = auth;
export const firebaseFirestore = db;


// ログイン用の処理
export const login = (email:string,password:string) =>{
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    // const cred_user = userCredential.user;
    console.log("success login");
    window.location.reload();
    // ...
  })
  .catch((error) => {
    // const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorMessage);
  });
}

export const logout = () =>{
  signOut(auth).then(() => {
    // Sign-out successful.
    window.location.reload();
  }).catch((error) => {
    // An error happened.
  });
}