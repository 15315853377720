import { useAuth } from '../contexts/AuthContext';
import { login } from '../firebase';
import SideBarList from './SideBarList';
import MainContents from './MainContents';

const Login = () => {
  const { user } = useAuth();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    login(email.value, password.value);
  };

  return (
    <>
      {!user ? (
        <section>
          <h1>ログイン</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>メールアドレス</label><br />
              <input name="email" type="email" placeholder="email" />
            </div>
            <div>
              <label>パスワード</label><br />
              <input name="password" type="password" placeholder="password" />
            </div>
            <div>
              <button >ログイン</button>
            </div>
          </form>
        </section>
      ) :
      <div>
          <aside>
          <SideBarList/>
          </aside>
        <main>
          <MainContents />
        </main>
      </div>
      }
    </>

  );
};

export default Login;